import { Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'food-type__template',
  standalone: true,
  template: `
<!--    *ngIf="registrationFormGroup.controls['chosen_service'].value != null"-->
<form [formGroup]="parentFormGroup">
    <div class="w-100 text-start pb-3" style="display: grid">
      <label style="color: #0009" id="food_type">{{ fieldDefinition.name }}</label>
      <mat-radio-group aria-labelledby="food_type" name="radio-{{fieldDefinition.id}}" [formControl]="$any(foodTypeFormControl)">
        <mat-radio-button value="VEGE">{{ itemVege.name }}</mat-radio-button>
        <mat-radio-button value="MEAT">{{ itemMeat.name }}</mat-radio-button>
        <mat-radio-button value="VEGAN">{{ itemVegan.name }}</mat-radio-button>
        <mat-error *ngIf="foodTypeFormControl.dirty && foodTypeFormControl.hasError('required')">
          {{ fieldDefinition.name }} je <strong>povinná</strong>
        </mat-error>
      </mat-radio-group>
    </div>

    <mat-list class="text-start" formArrayName="food_orders">
      <mat-list-item *ngFor="let foodOrderForm of foodOrdersFormArray.controls">
        <div [formGroup]="$any(foodOrderForm)">
          <span class="fw-bold pe-3">{{foodOrderForm.value['date'] | date: 'dd.MM.yyyy':undefined:'en'}}</span>
          <mat-checkbox formControlName="breakfast">Snídaně</mat-checkbox>
          <mat-checkbox formControlName="lunch">Oběd</mat-checkbox>
          <mat-checkbox formControlName="dinner">Večeře</mat-checkbox>
        </div>
      </mat-list-item>
    </mat-list>
</form>
  `,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatListModule,
    MatCheckboxModule,
  ]
})
export class FoodTypeTemplateComponent {
  @Input() foodTypeFormControl: AbstractControl;
  @Input() foodOrdersFormArray: FormArray;
  @Input() parentFormGroup: FormGroup;
  @Input() matcher: any;

  protected itemVege: { code: string, name: string };
  protected itemMeat: { code: string, name: string };
  protected itemVegan: { code: string, name: string };
  private _fieldDefinition: any;

  get fieldDefinition(): any {
    return this._fieldDefinition;
  }

  @Input()
  set fieldDefinition(value: any) {
    this._fieldDefinition = value;
    const items = JSON.parse(value.settings).items;
    this.itemVege = items.find(item => item.code === 'VEGE');
    this.itemMeat = items.find(item => item.code === 'MEAT');
    this.itemVegan = items.find(item => item.code === 'VEGAN');
  }
}
